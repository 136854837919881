import './FeesPage.css';

function FeesPage() {
  return (
    <div className="fees-page">
      <div className="membership-fees-section">
        <h1>Mitgliedsbeiträge</h1>
        <p>
          <i>Stand Januar 2019</i>
        </p>
        <table className="fees-table">
          <thead>
            <tr>
              <th></th>
              <th>Einmalige Aufnahmegebühr</th>
              <th>Jahres-Mitgliedsbeitrag</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Familien</td>
              <td>55,00 €</td>
              <td>100,00 €</td>
            </tr>
            <tr>
              <td>Erwachsene</td>
              <td>45,00 €</td>
              <td>75,00 €</td>
            </tr>
            <tr>
              <td>
                Jugendliche
                <br />
                <span>(14 - 18 Jahre)</span>
              </td>
              <td>15,00 €</td>
              <td>50,00 €</td>
            </tr>
            <tr>
              <td>
                Kinder
                <br />
                <span>(unter 14 Jahre)</span>
              </td>
              <td>keine</td>
              <td>25,00 €</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="rental-fees-section">
        <h1>Leihgebühren</h1>
        <h3>Mitglieder</h3>
        <table className="rental-fees-table">
          <thead>
            <tr>
              <th></th>
              <th>pro Tag</th>
              <th>pro Woche</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Kajak mit Doppelpaddel und Spritzdecke</td>
              <td>5,00€</td>
              <td>20,00€</td>
            </tr>
            <tr>
              <td>Kanadier mit Stechpaddeln</td>
              <td>7,50€</td>
              <td>30,00€</td>
            </tr>
            <tr>
              <td>KFZ - Bootsanhänger</td>
              <td>10,00€</td>
              <td>40,00€</td>
            </tr>
            <tr>
              <td>Stech- oder Doppelpaddel</td>
              <td>1,00€</td>
              <td>4,00€</td>
            </tr>
            <tr>
              <td>Helm</td>
              <td>1,00€</td>
              <td>4,00€</td>
            </tr>
            <tr>
              <td>Schwimmweste/-hilfe</td>
              <td>1,00€</td>
              <td>4,00€</td>
            </tr>
            <tr>
              <td>Neoprenanzug</td>
              <td>1,00€</td>
              <td>7,00€</td>
            </tr>
            <tr>
              <td colSpan={3}>
                Für Kinder und jugendliche Vereinsmitglieder ist das Ausleihen
                von Material frei. Ausgenommen ist das Ausleihen von
                Neoprenanzügen.
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Nicht Mitglieder</h3>
        <table className="rental-fees-table">
          <thead>
            <tr>
              <th></th>
              <th>pro Tag</th>
              <th>pro Woche</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Kajak mit Doppelpaddel und Spritzdecke</td>
              <td>10,00€</td>
              <td>40,00€</td>
            </tr>
            <tr>
              <td>Kanadier mit Stechpaddeln</td>
              <td>13,50€</td>
              <td>50,00€</td>
            </tr>
            <tr>
              <td>KFZ - Bootsanhänger</td>
              <td>20,00€</td>
              <td>80,00€</td>
            </tr>
            <tr>
              <td>Stech- oder Doppelpaddel</td>
              <td>1,50€</td>
              <td>6,00€</td>
            </tr>
            <tr>
              <td>Helm</td>
              <td>1,50€</td>
              <td>6,00€</td>
            </tr>
            <tr>
              <td>Schwimmweste/-hilfe</td>
              <td>1,50€</td>
              <td>6,00€</td>
            </tr>
            <tr>
              <td>Neoprenanzug</td>
              <td>2,00€</td>
              <td>14,00€</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FeesPage;
