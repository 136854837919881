import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

function ErrorPage() {
  const error = useRouteError();

  let errorMessage: string = '';

  if (isRouteErrorResponse(error)) {
    errorMessage = error.statusText;
  }

  return (
    <div>
      <h1>Fehler</h1>
      <p>Es ist ein unerwarteter Fehler aufgetreten:</p>
      <p>
        <i>{errorMessage}</i>
      </p>
    </div>
  );
}

export default ErrorPage;
