import './BoardPage.css';

function BoardPage() {
  return (
    <div className="board-page">
      <h2>Vorstand</h2>

      <h3>1. Vorsitzender</h3>
      <p>
        Max Reinhardt
        <br />
        Dierbachweg 18
        <br />
        76870 Kandel
        <br />
        <b>Mail:</b>{' '}
        <a href="mailto:vorsitzende@kanuverein-suew.de">
          mailto:vorsitzende@kanuverein-suew.de
        </a>
      </p>

      <h3>2. Vorsitzender</h3>
      <p>
        Manuel Seibel
        <br />
        <b>Mail:</b>{' '}
        <a href="mailto:vorsitzende@kanuverein-suew.de">
          mailto:vorsitzende@kanuverein-suew.de
        </a>
      </p>

      <h3>Kassenwartin</h3>
      <p>Ina Kolbinger</p>

      <h3>Materialwart</h3>
      <p>Frederik Haak</p>

      <h3>Materialwart (Vertretung)</h3>
      <p>Andre Kolbinger</p>

      <h3>Jugendwartin</h3>
      <p>Merle Hauser</p>

      <h3>Jugendwart (Vertretung)</h3>
      <p>Ole Wagenbrenner</p>

      <h3>Ökologie- & Wanderwart</h3>
      <p>Helmut Baaden</p>

      <h3>Ökologie- & Wanderwart (Vertretung)</h3>
      <p>Benjamin Kaltenbach</p>

      <h3>Leistungssportwart (Kanu Freestyle)</h3>
      <p>Ole Wagenbrenner</p>

      <h3>Schiftführerin</h3>
      <p>Lilian Hirschman</p>

      <h3>Schiftführer (Vertretung)</h3>
      <p>Nils Meyer</p>
    </div>
  );
}

export default BoardPage;
