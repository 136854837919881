import { NavLink } from 'react-router-dom';

function WelcomePage() {
  return (
    <div>
      <h2>
        Herzlich Willkommen beim Kanuverein Südliche Weinstraße e.V. in Bad
        Bergzabern
      </h2>
      <p>
        Seit 1990 betreiben wir als eingetragener Verein unter dem Dach des
        Deutschen Kanuverbandes Kanu- und Bergsport.
      </p>
      <p>
        Unser Schwerpunkt ist das Kajak- und Canadierfahren als Freizeitsport.
        Ein besonderes Anliegen ist uns dabei das Natur- und Gruppenerlebnis für
        Jugendliche.
      </p>
      <p>Das Grundgerüst unserer Aktivitäten:</p>
      <ul>
        <li>Schwimmbadtraining Sommer/Winter</li>
        <li>Freizeiten für Familien und Jugendliche in den Schulferien</li>
        <li>Übungsfahrten an Wildwasserkanäle für alle Könnensstufen</li>
        <li>Kanu-Freestyle Leistungssport</li>
        <li>Ausflüge auf dem Wasser mit Picknick usw.</li>
        <li>Bergtouren</li>
        <li>Wanderungen</li>
      </ul>
      <p>
        Es wird ausgefüllt und ergänzt von zahlreichen Unternehmungen, die
        unsere lizenzierten Übungsleiter immer wieder kurzfristig anbieten, je
        nach Wasserständen, Wetter und den Wünschen vorhandener Teilnehmer{' '}
        <NavLink to="aktuelles">
          <i>(siehe Aktuelles)</i>
        </NavLink>
        .
      </p>
      <p>
        Treffpunkt und Ausgangsbasis für unsere Unternehmungen ist das
        Vereinsheim und Bootshaus in Bad Bergzabern - Auf dem Viertel 11.
      </p>
      <p>
        Wer als Einzelperson oder Familie einmal bei uns reinschnuppern möchte
        kann ganz unkompliziert über die hier veröffentlichten Daten Kontakt
        aufnehmen.
      </p>
    </div>
  );
}

export default WelcomePage;
