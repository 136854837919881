import React, { useState, useEffect } from 'react';
import useBreakpoint, { Breakpoint } from '../Utils/UseBreakpoint';

type Props = {
  label: Label;
  route: string;
  active: boolean;
  routeFunc: (route: string) => void;
};

export type Label = LabelWithoutSubLabel | LabelWithSubLabel;
type LabelWithoutSubLabel = string;
type LabelWithSubLabel = {
  main: string;
  sub: string;
};

const isLabelWithSubLabel = (label: Label): boolean => {
  if (typeof label == 'string') {
    return false;
  }
  return true;
};

const getMainLabel = (label: LabelWithSubLabel): string => {
  return label.main;
};

const getSubLabel = (label: LabelWithSubLabel): string => {
  return label.sub;
};

type PaddleNavStyle = {
  backgroundColor: string;
  borderColor: string;
  labelColor: string;
  labelFontWeight: '400' | 'bold';
};

type PaddleNavStyles = {
  default: PaddleNavStyle;
  hover: PaddleNavStyle;
  active: PaddleNavStyle;
};

const paddleNavStyles: PaddleNavStyles = {
  default: {
    backgroundColor: '#0082cb',
    borderColor: '#000',
    labelColor: '#fff',
    labelFontWeight: '400',
  },
  hover: {
    backgroundColor: '#fff',
    borderColor: '#000',
    labelColor: '#000',
    labelFontWeight: 'bold',
  },
  active: {
    backgroundColor: '#0082cb',
    borderColor: '#000',
    labelColor: '#fff',
    labelFontWeight: 'bold',
  },
};

function PaddleNavItem(props: Props) {
  const currentBreakpoint = useBreakpoint();
  const [style, setStyle] = useState(
    props.active ? paddleNavStyles.active : paddleNavStyles.default,
  );

  useEffect(() => {
    updateStyles();
  });

  const setHover = (hover: boolean) =>
    hover ? setStyle(paddleNavStyles.hover) : updateStyles();
  const updateStyles = () =>
    props.active
      ? setStyle(paddleNavStyles.active)
      : setStyle(paddleNavStyles.default);

  const getWidth = (currentBreakpoint: Breakpoint): string => {
    switch (currentBreakpoint) {
      case 'xs':
        return '130px';
      case 'sm':
        return '150px';
      case 'md':
        return '200px';
      case 'lg':
        return '150px';
      case 'xl':
        return '180px';
      case 'xxl':
      default:
        return '200px';
    }
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => props.routeFunc(props.route)}
      style={{ width: getWidth(currentBreakpoint) }}
      className="nav-item"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        version="1.1"
        viewBox="0 0 115 45"
      >
        <defs>
          <path id="rect834" d="M7.182 4.914H110.37V31.75H7.182z"></path>
          <path d="M51.048 69.757H180.139V108.244H51.048z"></path>
        </defs>
        <g fillOpacity="1">
          <path
            fill={style.backgroundColor}
            stroke={style.borderColor}
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="1"
            d="M89.304 44.15c-8.492-.435-16.982-1.2-25.28-3.04-8.133-1.476-16.197-3.474-23.574-7.278-2.794-1.567-6.273-3.036-9.294-4.713-4.078-1.986-8.31-3.43-12.776-4.098-6.27-.618-11.495-.646-17.362-.527.019-1.894 0-4.046 0-6.077 5.513-.203 11.073.005 16.55-.448 4.596-.785 9.685-1.297 13.287-4.63 3.084-2.432 5.691-5.24 8.987-7.44C47.612 1.077 57.112.532 66.037.727c6.568.243 13.144.663 19.636 1.716 3.386.245 7.442 1.047 10.992 1.864 5.672 1.535 12.067 2.678 16.073 7.347 2.108 3.459 1.14 7.919-.016 11.51-.924 2.765-2.408 6.11-4.137 8.982-1.453 2.652-3.32 5.07-5.245 7.239-2.338 3.318-6.23 4.48-10.102 4.808-1.29.258-2.633-.02-3.934-.043z"
          ></path>
          <text
            fill={style.labelColor}
            fontSize="12.7"
            transform={
              isLabelWithSubLabel(props.label)
                ? 'translate(48 3)'
                : 'translate(42 10)'
            }
            xmlSpace="preserve"
            style={{
              lineHeight: '1.25',
              whiteSpace: 'pre',
              fontWeight: style.labelFontWeight,
            }}
          >
            <tspan x="0" y="0">
              <tspan x="0" dy="1.2em">
                {isLabelWithSubLabel(props.label)
                  ? getMainLabel(props.label as LabelWithSubLabel)
                  : (props.label as LabelWithoutSubLabel)}
              </tspan>
              {isLabelWithSubLabel(props.label) ? (
                <tspan x="0" dy="1.2em">
                  {getSubLabel(props.label as LabelWithSubLabel)}
                </tspan>
              ) : null}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
}

export default PaddleNavItem;
