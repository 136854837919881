import './NewsPage.css';

function NewsPage() {
  return (
    <div className="news-page">
      <h2>Aktuelles</h2>

      <h3>Trainingsbetrieb (Winter 2024/25)</h3>
      {/*<p>Auf Grund der anhaltenden technischen Probleme des Freibads des Rebmeerbad Bad Bergzabern <i>(Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern)</i> findest das Training aktuell im Schwimmerbecken zu folgenden geänderten Zeiten statt: <b>wöchentlich Mittwoch von 19:30 - 20:30 Uhr statt. Treffpunkt ist 19:00 Uhr vor dem Eingang zum Freibad.</b></p>*/}
      <p>
        Das Hallenbadtraining im Rebmeerbad Bad Bergzabern
        (Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern) findet aktuell{' '}
        <b>
          alle zwei Wochen Samstags von 15:00 - ca. 16:45 Uhr statt.{' '}
          <u>Treffpunkt ist 14:45 Uhr</u> vor dem Eingang zum Hallenbad.
        </b>
        <br />
        Die nächsten Termine sind der <b>16.11.2024, 30.11.2024, 14.12.2024</b>
      </p>

      <h3>Mitgliederversammlung 2025</h3>
      <p>
        <b>Save the Date:</b> Die Mitgliederversammlung und Fahrtenplanung 2025
        ist für Sonntag, den 02.02.2025 - ab 14:00 Uhr geplant.
        <br />
        Bitte haltet euch den Termin frei, die offizielle Einladung folgt via
        E-Mail oder Post.
        <br />
        <br />
        <b>
          Deine Kontaktdaten sind nicht mehr aktuell oder der Verein hat noch
          keine E-Mail - Adesse von dir?
        </b>
        <br />
        Dann gibt uns bitte Bescheid unter{' '}
        <a href="mailto:vorstand@kanuverein-suew.de">
          mailto:vorstand@kanuverein-suew.de
        </a>
        .
        <br />
        <i>
          Können wir dich nicht per E-Mail zur Mitgleiderversammlung einladen,
          müssen wir dies per Post tuen. Hierdurch entstehen dem Verein
          zusätzlicher finanzieller und organisatorischer Aufwand.
        </i>
      </p>
    </div>
  );
}

export default NewsPage;
